import axios from "axios";
import { getLang } from "@/utils/storage";
import { getItem } from "@/utils/storage-main.js";

import loginSDK from "@/utils/base";
import { loginGotin } from "@/utils/login.js";
import { Scheme } from "@/enum/scheme.js";

import router from "@/router";
import store from "@/store/index";
let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = (config) => {
  for (let p in pending) {
    if (pending[p].u === config.url + "&" + config.method) {
      //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
};

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "X-Ca-Key": 31920302,
  "X-Ca-Signature-Headers":
    "X-Ca-Request-Mode,X-Ca-Version,X-Ca-Stage,X-Ca-Key,X-Ca-Timestamp",
  "X-Ca-Stage": process.env.VUE_APP_X_CA_STAGE,
};

// // var stringToSign = headers.Method.toUpperCase()+"\n"+headers.Accept+"\n"+headers['Content-Type']+"\n"+headers.Date+"\n";

const instance = axios.create({
  timeout: 10000,
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
  headers,
});

// const getPaycenterUrl = () => {
//   return process.env.VUE_APP_PAYCENTER_URL;
// };
// const getBroadcastUrl = () => {
//   return process.env.VUE_APP_Broadcast_Backend;
// };
// const getAuthUrl = () => {
//   return process.env.VUE_APP_AUTH_URL;
// };
// const getApigwUrl = () => {
//   return process.env.VUE_APP_APIGW_URL;
// };
const getUserUrl = () => {
  return process.env.VUE_APP_USER;
};
const getCenterUserUrl = () => {
  return process.env.VUE_APP_CENTER_USER;
};
// const getCreatorUrl = () => {
//   return process.env.VUE_APP_CREATOR_URL;
// };
// const getGatewayUrl = () => {
//   return process.env.VUE_APP_GATEWAY_URL;
// };

const actions = () => {
  return new Map([
    [
      /^\/((user\/info)|(user\/edit)|(user\/upload-avatar)|(user\/regional_check)|auth|(user\/visitor-login)|(user\/logout))/,
      getUserUrl,
    ],
    [
      /^\/((center\/user\/card\/info)|(center\/user\/card\/update)|(user\/change-pwd)|(user\/check))/,
      getCenterUserUrl,
    ],
  ]);
};

const getBaseUrl = (url) => {
  let action = [...actions()].filter(([key]) => key.test(url));
  return action.map(([key, value]) => value.call(this))[0] || "";
};

instance.interceptors.request.use(
  (config) => {
    config.baseURL = getBaseUrl(config.url);
    if (!config.baseURL) {
      config.headers["X-I18n-Language"] = { en: "en-US", cn: "zh-CN" }[
        getLang()
      ];
    }

    config.headers["X-Ca-Nonce"] = Math.random().toString(36).substr(2, 15);
    config.headers["X-Ca-Timestamp"] = +new Date();
    // config.headers['Date'] = (new Date()).toUTCString();
    config.headers["Method"] = config.method;

    if (config.method === "post" || config.method === "put") {
      // config.data = qs.stringify(config.data)
    }
    if (!config.headers.Authorization) {
      config.headers.Authorization = getItem("Authorization");
    }
    if (
      config.url !== "/user/check" &&
      config.url.indexOf("/event/list/session") === -1 &&
      config.url.indexOf("/aliyun/get-stream") === -1 &&
      config.url.indexOf("/session/detail") === -1 &&
      config.url.indexOf("/upload/oss/cu/token") === -1 &&
      config.url.indexOf(".oss-cn-hangzhou.aliyuncs.com") === -1 &&
      config.url.indexOf("/web/messaging/auth") === -1
    ) {
      removePending(config); //在一个ajax发送前执行一下取消操作
      config.cancelToken = new cancelToken((c) => {
        // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
        pending.push({ u: config.url + "&" + config.method, f: c });
      });
    }
    if (!window.navigator.onLine) {
      // 判断请求异常信息中是否含有超时timeout字符串
      removePending(config);
    }
    // 有些接口就不想要 token （虽然我也很难受） 所以：
    // if(notAuthorizationList.includes(config.url)){
    //   delete config.headers.Authorization
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log(res);
    const code = res.code;
    if (code == 50) {
      const scheme = response.data.data.scheme;
      switch (scheme) {
        // 必须是在登录的前提下去补充个人信息
        case Scheme.LoginBindPhone:
          break;
        // 用户需要登陆
        case Scheme.NeedLogin:
          loginGotin("login");
          break;
        // 用户退出登录后跳转登录
        case Scheme.FlushLogin:
          break;
        // 用户退出登录后刷新
        case Scheme.FlushRefresh:
          break;
        // 用户需要前往活动x eid=x
        case Scheme.JumpEvent:
          break;
        // 用户需要前往日程x sid=x
        case Scheme.JumpSession:
          break;
        // 需要区分大陆和非大陆
        // 用户需前往绑定手机号/邮箱页面
        case Scheme.BindPhone:
          getItem("Authorization")
            ? loginSDK.login({
                type: "bindphone",
                lang: getLang(),
                redirect_url: window.location.origin,
              })
            : "";
          break;
        // 用户需前往绑定邮箱页面
        case Scheme.BindEmail:
          getItem("Authorization")
            ? loginSDK.login({
                type: "bindemail",
                lang: getLang(),
                redirect_url: window.location.origin,
              })
            : "";
          break;
        // 用户需跳转到404
        case Scheme.Notfound404:
          setTimeout(() => {
            router.replace({ name: "404" });
          }, 0);
          break;
        // 用户需要跳转到url
        case Scheme.JumpOther:
          break;
        // 用户创建主办方需填写问题
        case Scheme.JumpQuestion:
          break;
        // 活动密码
        case Scheme.JumpEventPwd:
          break;
        // 场次密码
        case Scheme.JumpSessionPwd:
          break;
        default:
          break;
      }
    }

    return res;

    // removePending(response.config); //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
  },
  (error) => {
    //置空请求列表
    if (error.response) {
      return Promise.reject(error);
    }
  }
);

export default instance;
