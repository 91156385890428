<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import testBg from '@/assets/imgs/bg-2.png'

export default {
  name: "App",

  data() {
    return {
      fps: 0,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
#app {
  font-family: "PingFang SC", "Microsoft YaHei";


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
</style>
