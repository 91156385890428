<template>
  <!-- 透明层 -->
  <div class="mesk" v-if="npcDialogShow">
    <template v-if="!isMobile">
      <img
        :src="imgUrl"
        alt=""
        class="big-img"
        @click="imgShow = false"
        v-if="imgShow"
      />
      <div
        :class="{ 'npc-interact': true, 'npc-interact-img': imgUrl }"
        v-if="npcDialogShow"
      >
        <div class="interact-content">
          <img :src="imgUrl" alt="" v-if="imgUrl" @click="imgShow = true" />

          <div v-html="content" style="white-space: pre"></div>
        </div>
        <div style="text-align: right">
          <template v-if="forum">
            <span @click="closeDialog" class="button mr-12">
              <div class="close-btn">关闭</div>
            </span>

            <span @click="linkTo('/forum')" class="button">进入论坛</span>
          </template>

          <template v-if="download">
            <span @click="closeDialog" class="button mr-12">
              <div class="close-btn">关闭</div>
            </span>

            <span @click="openUserDownload('/download')" class="button"
              >生成数字照片</span
            >
          </template>

          <template v-if="!download && !forum">
            <span @click="closeDialog" class="button">了解</span>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        :class="{
          'npc-interact-mobile': true,
          'npc-interact-img-mobile': imgUrl,
        }"
        v-if="npcDialogShow"
      >
        <div class="interact-content-mobile">
          <img :src="imgUrl" alt="" v-if="imgUrl" />

          <div v-html="content" style="white-space: pre"></div>
        </div>
        <div style="text-align: right">
          <template v-if="forum">
            <span @click="closeDialog" class="button mr-12">
              <div class="close-btn">关闭</div>
            </span>

            <span @click="linkTo('/forum')" class="button">进入论坛</span>
          </template>

          <template v-if="download">
            <span @click="closeDialog" class="button mr-12">
              <div class="close-btn">关闭</div>
            </span>

            <span @click="openUserDownload('/download')" class="button"
              >生成数字照片</span
            >
          </template>

          <template v-if="!download && !forum">
            <span @click="closeDialog" class="button">了解</span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import isMobileUtil from "@/utils/isMobile.js";
import html2canvas from "html2canvas";
import loginSDK from "../utils/base.js";

export default {
  data() {
    return {
      imgShow: false,
      npcDialogShow: false,
      isMobile: isMobileUtil(),
      forum: false,
      download: false,
      contentList: [
        "欢迎来到“In+hub”创新社区第二期加速营甄选日\n打卡点领取专属数字藏品\n按箭头方向浏览沿途展位\n终点舞台处为会场入口",
        "欢迎来到“In+hub”创新社区展位\n进入热区，点击屏幕可查看更多\n展位主页内有直播间\n可与主播进行交流互动",
        "点击领取专属数字藏品\n请挑选喜爱的人物形象进行个性化定制\n定制完成即可获得数字藏品",
        "欢迎来到璞跃中国展位\n进入热区，点击屏幕可查看更多\n展位主页内有直播间\n可与主播进行互动交流",
        "欢迎来到浦发银行展位\n进入热区，点击屏幕可查看更多\n展位主页内有直播间\n可与主播进行互动交流",
        "迎参观金融数字化展区\n进入热区可点击屏幕查看丰富信息\n点击互动按键与科创企业专家互动交流",
        "欢迎参观绿色低碳与产业数字化展区\n进入热区可点击屏幕查看丰富信息\n点击互动按键与科创企业专家互动交流",
        "欢迎参观移动出行与数字生活展区\n进入热区可点击屏幕查看丰富信息\n点击互动按键与科创企业专家互动交流",
        "欢迎来到投资展区\n进入热区，点击屏幕可查看更多",
        "欢迎来到“In+hub”创新社区第二期加速营甄选日\n特别提醒“在入口处数字藏品打卡点领取专属数字藏品哦”",
      ],

      content: "",
      imgUrl: "",
    };
  },

  methods: {
    closeDialog() {
      this.imgShow = false;
      this.npcDialogShow = false;
      this.download = false;
      this.forum = false;
    },

    routerTo(url) {
      this.$router.push(url);
    },
    openUserDownload() {
      this.closeDialog();
      this.$mittBus.$emit("userDownload", true);
    },
    linkTo() {
      const url = loginSDK.generateUrlWithQuery(
        process.env.VUE_APP_HOST + "/newview/live/forum/nJlaXwdaQe8vEpDj"
      );
      location.href = url;
    },
  },

};
</script>

<style scoped lang="scss">
.mr-12 {
  margin-right: 12px;
}
.mesk {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
.big-img {
  position: absolute;
  left: 50%;
  top: 100px;
  width: 576px;
  height: auto;
  transform: translate(-50%);
}
.npc-interact {
  position: absolute;
  background: #09080a;
  border-radius: 16px;
  width: 578px;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%);
  padding: 24px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}

.npc-interact-img {
  width: 842px;
}

.interact-content {
  display: flex;
  img {
    width: 240px;
    height: 136px;
    margin-right: 24px;
    // height: auto;
  }
}
.button {
  // align-items: ;
  display: inline-block;
  width: 126px;
  height: 40px;
  background: linear-gradient(114.44deg, #624af2 0%, #50ddc3 100%);
  border-radius: 8px;
  line-height: 40px;
  margin-top: 16px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  padding: 1px;
  // border: 1px solid transparent;
}

.close-btn {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #09080a;

  color: linear-gradient(114.44deg, #624af2 0%, #50ddc3 100%);
}

.downaload-btn {
  width: 126px;
  height: 40px;
  background: linear-gradient(114.44deg, #624af2 0%, #50ddc3 100%);
  border-radius: 8px;
}
.big-img-mobile {
  position: absolute;
  left: 50%;
  top: 50%;
  width: calc(100% - 32px);
  height: auto;
  transform: translate(-50%, -50%);
}
.npc-interact-mobile {
  position: absolute;
  background: #09080a;
  border-radius: 16px;
  width: calc(100% - 32px);
  bottom: 16px;
  left: 50%;
  transform: translate(-50%);
  padding: 16px;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  box-sizing: border-box;
}

.interact-content-mobile {
  // display: flex;
  img {
    width: 254px;
    height: 144px;
    margin: 0 auto;
    display: block;
    margin-bottom: 16px;
    // height: auto;
  }
}
</style>
