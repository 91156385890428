var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mesk",class:{ 'mesk-mobile': _vm.isMobile }},[_c('div',{staticClass:"mesk-bg"}),_c('div',{class:{ 'select-area': !_vm.isMobile, 'select-area-mobile': _vm.isMobile }},[_vm._m(0),_vm._l((_vm.maleRoleList),function(val,ind){return _c('div',{key:val.url,class:{
        'select-item': true,
        'select-male': true,
        'selected-male': _vm.user_select == ind + 1,
      }},[_c('div',{staticClass:"select-male-content"},[_c('img',{staticClass:"people",attrs:{"src":require('@/assets/imgs/user-select/' + val.url + '.png'),"alt":""},on:{"click":function($event){_vm.user_select = ind + 1}}})])])}),_vm._l((_vm.femaleRoleList),function(val,ind){return _c('div',{key:val.url,class:{
        'select-item': true,
        'select-female': true,
        'selected-female': _vm.user_select == ind + 4,
      }},[_c('div',{staticClass:"select-female-content"},[_c('img',{staticClass:"people",attrs:{"src":require('@/assets/imgs/user-select/' + val.url + '.png'),"alt":""},on:{"click":function($event){_vm.user_select = ind + 4}}})])])}),(_vm.isMobile)?_c('img',{staticClass:"button",staticStyle:{"width":"140px","height":"40px"},attrs:{"src":require("@/assets/imgs/button-m.png"),"alt":""},on:{"click":_vm.saveRole}}):_c('img',{staticClass:"button",staticStyle:{"width":"160px","height":"48px"},attrs:{"src":require("@/assets/imgs/button.png"),"alt":""},on:{"click":_vm.saveRole}})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"select-area-title"},[_vm._v(" Please choose your avatar "),_c('br'),_c('span',[_vm._v(" 请选择你的形象 ")])])}]

export { render, staticRenderFns }