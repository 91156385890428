<template>
  <!-- 透明层 -->
  <div class="mesk" :class="{ 'mesk-mobile': isMobile }">
    <div class="mesk-bg"></div>
    <div :class="{ 'select-area': !isMobile, 'select-area-mobile': isMobile }">
      <h3 class="select-area-title">
        Please choose your avatar
        <br />
        <span> 请选择你的形象 </span>
      </h3>
      <div
        :class="{
          'select-item': true,
          'select-male': true,
          'selected-male': user_select == ind + 1,
        }"
        v-for="(val, ind) in maleRoleList"
        :key="val.url"
      >
        <div class="select-male-content">
          <img
            :src="require('@/assets/imgs/user-select/' + val.url + '.png')"
            alt=""
            class="people"
            @click="user_select = ind + 1"
          />
        </div>
      </div>

      <div
        :class="{
          'select-item': true,
          'select-female': true,
          'selected-female': user_select == ind + 4,
        }"
        v-for="(val, ind) in femaleRoleList"
        :key="val.url"
      >
        <div class="select-female-content">
          <img
            :src="require('@/assets/imgs/user-select/' + val.url + '.png')"
            alt=""
            class="people"
            @click="user_select = ind + 4"
          />
        </div>
      </div>

      <!-- <div class="button" @click="saveRole">
        <p>Enter</p>
        <p>进入线上空间</p>
      </div> -->
      <img
        src="@/assets/imgs/button-m.png"
        alt=""
        style="width: 140px; height: 40px"
        class="button"
        @click="saveRole"
        v-if="isMobile"
      />
      <img
        src="@/assets/imgs/button.png"
        alt=""
        style="width: 160px; height: 48px"
        class="button"
        @click="saveRole"
        v-else
      />
    </div>
  </div>
</template>

<script>
import isMobileUtil from "@/utils/isMobile.js";

export default {
  data() {
    return {
      imgShow: false,
      isMobile: isMobileUtil(),
      user_select: 1,
      femaleRoleList: [
        {
          url: "Girl_1",
          id: "2",
          sex: "female",
          ornaments: "",
        },
        {
          url: "Girl_2",
          id: "4",
          sex: "female",
          ornaments: "female_accessorypack_10",
        },

        {
          url: "Girl_3",
          id: "7",
          sex: "female",
          ornaments: "female_accessorypack_09",
        },
      ],

      maleRoleList: [
        {
          url: "Boy_1",
          id: "1",
          sex: "male",
          ornaments: "male_accessorypack_10",
        },
        {
          url: "Boy_2",
          id: "4",
          sex: "male",
          ornaments: "",
        },
        {
          url: "Boy_3",
          id: "5",
          sex: "male",
          ornaments: "male_accessorypack_09",
        },
      ],
    };
  },

  components: {},
  computed: {
    allRoleList() {
      return [...this.maleRoleList, ...this.femaleRoleList];
    },
  },

  methods: {
    closeDialog() {},

    saveRole() {
      let arr = [...this.maleRoleList, ...this.femaleRoleList];
      localStorage.setItem(
        "user_select",
        JSON.stringify(
          Object.assign({}, arr[this.user_select - 1], {
            index: this.user_select,
          })
        )
      );
      this.$router.push("/");
    },
  },

  created() {
    // 进入角色选择页应停止渲染
    if (window.game) {
      console.log(window.game);
      window.game.dispose();
    }
    // if (localStorage.getItem("user_select")) {
    //   this.$router.push("/");
    // }
  },
};
</script>

<style scoped lang="scss">
.mesk {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../assets/imgs/bg-3.png");

  background-size: cover;

  .mesk-bg {
    width: 100%;
    height: 100%;
    // background-color: rgba(10, 10, 10, 0.5);
  }
}

.mesk-mobile {
  background-image: url("../assets/imgs/bg-4.png");
}

.select-area {
  width: 700px;
  height: 700px;
  padding: 30px 80px;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.85) 2.47%,
    rgba(0, 0, 0, 0.595) 101.36%
  );
  backdrop-filter: blur(16px);
  border-radius: 20px;

  &-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin-bottom: 14px;

    span {
      font-size: 18px;
    }
  }

  .select-item {
    width: 140px;
    height: 212px;
    border: 4px solid transparent;
    border-radius: 16px;
    display: inline-block;

    position: relative;
    margin-right: 18px;
    box-sizing: content-box;
    padding: 10px;

    &:nth-child(4) {
      margin-right: 0;
    }

    &:nth-child(7) {
      margin-right: 0;
    }

    .select-male-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #e7ebff;
    }

    .select-female-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #f7ebf7;
    }

    &:hover {
      border: 4px solid rgba(217, 219, 233, 0.3);
      border-radius: 16px;
    }

    .people {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      // padding: 20px;
    }
  }

  .select-male {
    .sex-icon {
      background: url("../assets/imgs/sex/male.svg") 100% 100% no-repeat;
    }
  }

  .select-female {
    margin-top: 32px;

    .sex-icon {
      background: url("../assets/imgs/sex/female.svg") 100% 100% no-repeat;
    }
  }

  .selected-male {
    border: 4px solid #d5001c !important;
  }

  .selected-female {
    border: 4px solid #d5001c !important;
  }

  .button {
    // align-items: ;

    margin-top: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}

.select-area-mobile {
  position: absolute;
  width: 343px;
  height: 454px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 2.44%,
    rgba(0, 0, 0, 0.602) 100%
  );
  backdrop-filter: blur(5px);
  border-radius: 16px;
  padding: 10px;

  .select-area-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 10px;

    span {
      font-size: 14px;
    }
  }

  .select-item {
    width: 104px;
    height: 148px;
    border-radius: 12px;
    display: inline-block;

    position: relative;
    margin-right: 4px;

    border: 2px solid transparent;
    padding: 6px;

    .select-male-content {
      width: 100%;
      height: 100%;
      border: 6px solid transparent;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #e7ebff;
    }

    .select-female-content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #f7ebf7;
    }

    &:nth-child(4) {
      margin-right: 0;
    }

    &:nth-child(7) {
      margin-right: 0;
    }

    .people {
      width: 100%;
      height: 100%;
    }
  }

  .selected-male {
    border: 2px solid #d5001c !important;
  }

  .selected-female {
    border: 2px solid #d5001c !important;
  }

  .button {
    margin-top: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
