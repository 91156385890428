import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueBus from "vue-bus";
import mitt from 'mitt'

import "@/assets/css/style.css";
import "@/assets/css/3d.css";

Vue.use(VueBus);

// import Axios from 'axios'
// Vue.prototype.$axios=Axios


Vue.prototype.$mittBus = mitt()
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
