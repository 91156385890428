import instance from "@/utils/http";

// 用户是否购票
async function isBuyTicket(sessionId = 0) {
  const eventHash = "jylJMGOrAdNXdmoA";
  let isBuy = false;

  await instance
    .get(
      `https://paycenter.gotin.online/ticket/event/${eventHash}/session/${sessionId}`
    )
    .then(({ data, code }) => {
      if (code === 0) {
        isBuy = data.state;
      }
    })
    .catch((err) => {
      // err后正常执行，等待协议自身跳转
      isBuy = true;
    });
  // return false
  return isBuy;
}

export { isBuyTicket };
