<template>
  <div class="sceneContainer">
    <!-- 3D场景 -->
    <canvas class="bjsCanvas" ref="bjsCanvas" />
    <div class="mask" v-if="imgShow">
      <div class="mask-content">
        <img src="@/assets/imgs/bg-5.png" alt="" class="img1" />
        <img
          src="@/assets/imgs/button-2.svg"
          alt=""
          class="img2"
          @click="closeDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { createScene } from "../scenes/MyFirstScene";

import isMobile from "@/utils/isMobile";
import { Game } from "@frontend/gotin-3d";
import loginSDK from "../utils/base.js";

let api;
let scene;

let bjCanvas;
let joyStick;
export default {
  name: "BabylonScene",

  data() {
    return {
      scene: null,
      engine: null,
      interval: null,
      isMobile: isMobile(),
      imgShow: false,
    };
  },

  mounted() {
    let data = isMobile();
    //true-移动，false-pc
    let criterion = data ? true : false;
    bjCanvas = this.$refs.bjsCanvas;

    let userData = JSON.parse(localStorage.getItem("user_select"));
    if (bjCanvas) {
      const game = new Game(bjCanvas, {
        emitter: this.$mittBus,
        config: { id: 123 },
      });
      this.$mittBus.emit("show", "game链接成功");
      game.start();
      window.game = game;
      this.$mittBus.on("enterEvent", () => {
        console.log(2323232);
        const url = loginSDK.generateUrlWithQuery(
          process.env.VUE_APP_HOST + "/newview/home"
        );

        location.href = url;
      });

      this.$mittBus.on("loadingDown", () => {
        console.log(232323);
        if (!isMobile() && localStorage.getItem("is_old") != "true") {
          this.imgShow = true;
        }
      });
    }
  },

  methods: {
    closeDialog() {
      localStorage.setItem("is_old", true);
      this.imgShow = false;
    },
  },

  unmounted() {
    // 清除game对象
    window.game.dispose();
    window.game = null;
  },
};
</script>

<style scoped lang="scss">
.audio {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 20px;
  width: 36px;
  height: 36px;
  background: url("../assets/imgs/audio.png");
}

.close-audio {
  background: url("../assets/imgs/close-audio.png");
}

.color1 {
  position: static;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 0px;

  /*border: 1px solid rgba(253, 22, 156, 1);*/

  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 50%;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 18px;

  cursor: pointer;
}

.color2 {
  position: static;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 0px;

  border: 2px solid rgba(253, 22, 156, 1);
  box-sizing: border-box;
  border-radius: 50%;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 18px;
  cursor: pointer;
}

.qwe {
  position: static;
  width: 48px;
  height: 48px;
  left: 0px;
  top: 0px;
  margin-right: 18px;

  border-radius: 50%;
}

.bjsCanvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.sceneContainer {
  width: 100%;
  height: 100%;
}

.bjsCanvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.random2 {
  position: static;
  width: 28px;
  height: 20px;
  right: 32px;
  top: calc(50% - 20px / 2);

  /* Bold/H4 */

  font-family: "PingFang SC", "Microsoft YaHei";

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Colors/Primary */

  color: #ed2e79;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.accomplish {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;

  width: 120px;
  height: 40px;
  left: 138px;
  top: 0px;

  /* Colors/Primary */

  background: #ed2e79;
  /* Colors/Primary */

  border: 1px solid #ed2e79;
  box-sizing: border-box;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 18px;
  color: #ffffff;
  font-size: 14px;
}

.clothes {
  left: 0px;
  top: 0px;
  /*border: 1px solid rgba(253, 22, 156, 1);*/

  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 18px;

  cursor: pointer;
}

.clothes2 {
  left: 0px;
  top: 0px;
  border: 1px solid rgba(253, 22, 156, 1);

  box-sizing: border-box;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 18px;

  cursor: pointer;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0px;
  .mask-content {
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .img1 {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
  }
  .img2 {
    position: absolute;
    position: absolute;
    left: 50%;
    cursor: pointer;
    transform: translateX(-50%);
    bottom: 40px;
  }
}
</style>
