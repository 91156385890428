const i18nLanguage = "i18nLanguage";
const getStorageKey = (userId, sessionId) => {
  return `session_password_${userId}_${sessionId}`;
};

function defaultLocale() {
  //判断浏览器的首选语言
  if (navigator.language.indexOf("zh") >= 0) {
    return "cn";
  }
  // 该3d项目仅为中文
  return "cn";
}

function setLang(value) {
  if (!value) {
    value = defaultLocale();
  }
  localStorage.setItem(i18nLanguage, value);
}

function getLang() {
  return localStorage.getItem(i18nLanguage) || defaultLocale();
}

export { getStorageKey, setLang, getLang };
