<template>
  <div class="home">
    <BabylonScene v-if="isShow" />
    <expo></expo>
    <interact></interact>
    <!-- <download></download> -->
  </div>
</template>

<script>
import BabylonScene from "@/components/BabylonScene.vue";
import expo from "@/components/expo.vue";
import interact from "@/components/interact.vue";
// import download from "@/components/download.vue";

import { loginGotin, isLogged } from "../utils/login.js";
import loginSDK from "../utils/base.js";
import { isBuyTicket } from "@/utils/tickets";

import { getQueryString } from "@/utils/getQuery";
import isMobileUtil from "@/utils/isMobile.js";
import instance from "@/utils/http";

export default {
  name: "Home",

  data() {
    return {
      isMobile: isMobileUtil(),
      isShow: false,
    };
  },
  components: {
    BabylonScene,
    expo,
    interact,
    // download,
  },

  async created() {
    // if (process.env.NODE_ENV !== "development") {
    if (getQueryString("token")) {
      await loginSDK.save_auth({
        token: getQueryString("token"),
        refresh_token: getQueryString("refresh_token"),
        expired_time: getQueryString("expired_time"),
      });
      // 重置地址栏token
      location.replace(location.origin);
    } else {
      if (!isLogged()) {
        await loginGotin("login");
        return;
      } else {
        // 判断协议是否跳转
        await instance
          .get("https://api-gateway.gotin.online/web/user/regional_check")
          .then(async () => {
            // 检测是否买票，未买票跳转至2d界面
            const isBuy = await isBuyTicket();
            if (!isBuy) {
              console.log(
                loginSDK.generateUrlWithQuery(process.env.VUE_APP_HOST)
              );
              location.href = loginSDK.generateUrlWithQuery(
                process.env.VUE_APP_HOST
              );
              return;
            }
          });
      }
    }
    console.log("是否买票");

    this.isShow = true;
    // }

    // 禁止右键菜单
    document.oncontextmenu = function () {
      return false;
    };

    // if (document.hidden !== undefined) {
    //   document.addEventListener("visibilitychange", () => {
    //     if (document.hidden === false && this.isMobile) {
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
    //     }
    //   });
    // }

    const role = window.localStorage.getItem("user_select");
    if (!role) {
      this.$router.push("role");
    }
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
}
</style>
