<template>
  <div class="expo" v-show="isShow">
    <div
      :class="{
        'expo-container': !isMobile,
        'expo-container-mobile': isMobile,
      }"
    >
      <div
        :class="{ 'close-btn': true, 'close-btn-mobile': isMobile }"
        @click="closeDialog"
      >
        <img src="@/assets/imgs/close.png" alt="" />
      </div>
      <iframe
        :src="url"
        frameborder="0"
        width="100%"
        height="100%"
        v-if="isShow"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
import isMobileUtil from "@/utils/isMobile.js";
import isWechatUtil from "@/utils/isWechat.js";

import forumdata from "@/components/forumData";
import loginSDK from "../utils/base.js";

export default {
  data() {
    return {
      isShow: false,
      // url: "http://localhost:8080/newview/home?isFull=true",
      isMobile: isMobileUtil(),
      url: "",
    };
  },

  mounted() {
    this.$mittBus.on("enterExpo", (data) => {
      let num = parseInt(data.id);
      this.url = loginSDK.generateUrlWithQuery(
        process.env.VUE_APP_HOST + "/newview/info/companyprofile",
        `&exhibitorId=51dec292-eb49-4da0-9f2d-f8326714d2b1-expo${num}&isFull=true&init=true"`
      );

      window.game.stop();
      this.isShow = true;
    });

    this.$mittBus.on("enterForum", (data) => {
      let num = parseInt(data.id);
      // const url = loginSDK.generateUrlWithQuery(
      //   process.env.VUE_APP_HOST +
      //     "/newview/live/forum/" +
      //     forumdata[num - 1].hash
      // );

      const url = loginSDK.generateUrlWithQuery(
        process.env.VUE_APP_HOST +
          "/newview/live/waitingroom/forum/JY2EK8Z9ZxwM7o4Z"
      );
      if (!isWechatUtil) {
        window.open(url);
      } else {
        location.href = url;
      }
    });

    this.$mittBus.on("enterVideo", (data) => {
      let num = parseInt(data.id);
      let contentUrl = [
        "72100fe7-6e1c-4615-b12e-438231fadff3",
        "e2ccad99-e825-4192-b06b-ead4c0241915",
        "883521d0-ab4d-4920-8bdb-d1d9b51d8595",
        "38fefefc-d8a8-458f-ac25-8723a61c77e9",
      ];
      contentUrl = contentUrl.reverse();
      const url = loginSDK.generateUrlWithQuery(
        process.env.VUE_APP_HOST +
          "/newview/info/discoverdetail?contentId=" +
          contentUrl[num - 1] +
          "&isFull=true&init=true&hideMore=true"
      );

      this.url = url;
      window.game.stop();
      this.isShow = true;
    });
  },

  methods: {
    closeDialog() {
      this.isShow = false;
      window.game.start();
    },
  },
};
</script>

<style scoped lang="scss">
.expo {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #14142a;
  z-index: 100;
}

// .mesh {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: #14142A;
//   // opacity: 0.5;
// }

.expo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1016px;
  height: 600px;
}

.expo-container-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.close-btn {
  position: absolute;
  top: -32px;
  right: 5px;

  img {
    width: 20px;
  }
}

.close-btn-mobile {
  top: 15px;
  right: 15px;
}
</style>
