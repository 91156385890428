import loginSDK from "@/utils/base.js";
import { getLang } from "@/utils/storage.js";

function setFrom(backTo, query) {
  let gotinFrom = backTo
    ? backTo.indexOf("http") > -1
      ? backTo
      : location.origin + "/newview/home?toLive=&backTo=" + backTo
    : location.href;
  gotinFrom = gotinFrom.replace(/token=.*?$/, "");

  let queryString;
  if (query) {
    queryString = query.join("&");
  }

  if (queryString) {
    gotinFrom =
      gotinFrom.indexOf("?") > -1
        ? gotinFrom + "&" + queryString
        : gotinFrom + "?" + queryString;
  }
  return gotinFrom;
}

function loginGotin(type, eventId, ticketId, redirect) {
  let redirectUrl;
  if (ticketId) {
    redirectUrl = setFrom("", [`ticketId=${ticketId}`]);
  } else {
    redirectUrl = setFrom();
  }
  redirectUrl = redirect ? redirect : redirectUrl;

  loginSDK.login({
    type: type,
    lang: getLang(),
    eventId: eventId,
    redirect_url: encodeURIComponent(redirectUrl),
  });
}
// 静默授权登录拿openid
function silentAuthLogin(backTo) {
  setFrom(backTo);
  loginSDK.login({
    type: "login",
    lang: getLang(),
    name: "openid",
  });
}

function isLogged() {
  return loginSDK.getCurrentToken() || false;
}
const saveAuth = (token, refreshToken, expireTime) => {
  loginSDK.save_auth({
    token: token,
    refresh_token: refreshToken,
    expired_time: expireTime,
  });
};

export { loginGotin, silentAuthLogin, isLogged, saveAuth };
